<template>
<scale-box>
  <div class="search-box">
        <div class="box-left">
          <div class="left-top">
              <div class="top-title" :class="change"> 
                <span class="span1" @click="changetype('sel')">筛选项</span>
                <!-- <span class="span2" @click="changetype('mod')">检索记录</span> -->
              </div>
              <div class="left-chose" :class="change2">
                <!-- <div class="div1" @click="stretch1('1')" @mouseenter="showback('one')" @mouseleave="leave()">
                  <span>全部国家</span>
                  <img :src="require('/static/images/patentIcon.png')" alt="">
                </div> -->
                <!-- <div class="country" v-show="isTags('1')">
                   <div class="con" :class="{ active1: style1 }">
                      <div>
                      <span>中国</span><span>(11185625)</span>
                    </div>
                    <div class="country-type">
                      <div>
                        <span>发明专利</span><span>(11185625)</span>
                      </div>
                       <div>
                        <span style="margin-left:48px">发明授权专利</span><span>(11185625)</span>
                      </div>
                       <div>
                        <span style="margin-left:48px">发明公开专利</span><span>(11185625)</span>
                      </div>
                      <div>
                        <span>实用新型专利</span><span>(11185625)</span>
                      </div>
                      <div>
                        <span>外观专利</span><span>(11185625)</span>
                      </div>
                    </div>
                   </div>
                    <div class="morebut" @click="more1" v-show="!style1">+ 更多</div>
                    <div class="morebut" @click="les1" v-show="style1">- 收起</div>
                </div> -->
                <div class="div2" @click="stretch1('2')" @mouseenter="showback('two')" @mouseleave="leave()">
                  <span>申请人</span>
                  <img :src="require('/static/images/patentIcon.png')" alt="">
                </div>
                <div class="applant" v-show="isTags('2')">
                    <div class="con2" :class="{ active2: style2 }">
                        <div v-for="(v,k) in arr2" :key="k" @click="pushS(v.key)">
                          <span >{{v.key}}</span>
                          <span>({{v.count}})</span>
                        </div>
                    </div>
                    <div class="morebut" @click="more2" v-show="!style2">+ 更多</div>
                    <div class="morebut" @click="les2" v-show="style2">- 收起</div>
                </div>
                <div class="div3" @click="stretch1('3')" @mouseenter="showback('three')" @mouseleave="leave()">
                  <span>申请日</span>
                  <img :src="require('/static/images/patentIcon.png')" alt="">
                </div>
                <div class="applant-date" v-show="isTags('3')"> 
                     <div class="con2" :class="{ active3: style3 }">
                        <!-- <div class="chosedate">
                          <div>
                              <el-date-picker
                                v-model="value1"
                                type="date"
                                :clearable="false"
                              ></el-date-picker>
                            <span style="margin-left:3px;">-</span>
                          </div>
                          <div>
                            <el-date-picker
                            v-model="value1"
                            type="date"
                            :clearable="false"
                            ></el-date-picker>
                          <span class="date-confir">确定</span>
                          </div>
                        </div> -->
                        <div v-for="(v,k) in arr3" :key="k">
                          <span >{{v.key}}</span>
                          <span>({{v.count}})</span>
                        </div>
                    </div>
                    <div class="morebut" @click="more3" v-show="!style3">+ 更多</div>
                    <div class="morebut" @click="les3" v-show="style3">- 收起</div>
                </div>
                <div class="div4" @click="stretch1('4')" @mouseenter="showback('four')" @mouseleave="leave()">
                  <span>公开日</span>
                  <img :src="require('/static/images/patentIcon.png')" alt="">
                </div>
                <div class="applant-date" v-show="isTags('4')"> 
                     <div class="con2" :class="{ active4: style4 }">
                        <!-- <div class="chosedate">
                          <div>
                              <el-date-picker
                                v-model="value1"
                                type="date"
                                :clearable="false"
                              ></el-date-picker>
                            <span style="margin-left:3px;">-</span>
                          </div>
                          <div>
                            <el-date-picker
                            v-model="value1"
                            type="date"
                            :clearable="false"
                            ></el-date-picker>
                          <span class="date-confir">确定</span>
                          </div>
                        </div> -->
                        <div v-for="(v,k) in arr4" :key="k">
                          <span >{{v.key}}</span>
                          <span>({{v.count}})</span>
                        </div>
                    </div>
                    <div class="morebut" @click="more4" v-show="!style4">+ 更多</div>
                    <div class="morebut" @click="les4" v-show="style4">- 收起</div>
                </div>
                <div class="div5" @click="stretch1('5')" @mouseenter="showback('five')" @mouseleave="leave()">
                  <span>授权日</span>
                  <img :src="require('/static/images/patentIcon.png')" alt="">
                </div>
                 <div class="applant-date" v-show="isTags('5')"> 
                     <div class="con2" :class="{ active5: style5 }">
                        <!-- <div class="chosedate">
                          <div>
                              <el-date-picker
                                v-model="value1"
                                type="date"
                                :clearable="false"
                              ></el-date-picker>
                            <span style="margin-left:3px;">-</span>
                          </div>
                          <div>
                            <el-date-picker
                            v-model="value1"
                            type="date"
                            :clearable="false"
                            ></el-date-picker>
                          <span class="date-confir">确定</span>
                          </div>
                        </div> -->
                        <div v-for="(v,k) in arr5" :key="k">
                          <span >{{v.key}}</span>
                          <span>({{v.count}})</span>
                        </div>
                    </div>
                    <div class="morebut" @click="more5" v-show="!style5">+ 更多</div>
                    <div class="morebut" @click="les5" v-show="style5">- 收起</div>
                </div>
                <!-- <div class="div6" @mouseenter="showback('six')" @mouseleave="leave()">
                  <span>法律状态</span>
                  <img :src="require('/static/images/patentIcon.png')" alt="">
                </div>
                <div class="div7" @click="stretch1('7')" @mouseenter="showback('seven')" @mouseleave="leave()">
                  <span>法律事件</span>
                  <img :src="require('/static/images/patentIcon.png')" alt="">
                </div>
                 <div class="applant" v-show="isTags('7')">
                    <div class="con2" :class="{ active7: style7 }">
                        <div v-for="(v,k) in applant" :key="k">
                          <span>{{v.name}}</span><span>({{v.count}})</span>
                        </div>
                    </div>
                    <div class="morebut" @click="more7" v-show="!style7">+ 更多</div>
                    <div class="morebut" @click="les7" v-show="style7">- 收起</div>
                </div> -->
                <div class="div8" @click="stretch1('8')" @mouseenter="showback('eight')" @mouseleave="leave()">
                  <span>分类号（大类）</span>
                  <img :src="require('/static/images/patentIcon.png')" alt="">
                </div>
                 <div class="applant" v-show="isTags('8')">
                    <div class="con2" :class="{ active8: style8 }">
                        <div v-for="(v,k) in arr8" :key="k">
                          <span >{{v.key}}</span>
                          <span>({{v.count}})</span>
                        </div>
                    </div>
                    <div class="morebut" @click="more8" v-show="!style8">+ 更多</div>
                    <div class="morebut" @click="les8" v-show="style8">- 收起</div>
                </div>
                <div class="div9" @click="stretch1('9')" @mouseenter="showback('nine')" @mouseleave="leave()">
                  <span>分类号（小类）</span>
                  <img :src="require('/static/images/patentIcon.png')" alt="">
                </div>
                 <div class="applant" v-show="isTags('9')">
                    <div class="con2" :class="{ active9: style9 }">
                        <div v-for="(v,k) in arr9" :key="k">
                          <span >{{v.key}}</span>
                          <span>({{v.count}})</span>
                        </div>
                    </div>
                    <div class="morebut" @click="more9" v-show="!style9">+ 更多</div>
                    <div class="morebut" @click="les9" v-show="style9">- 收起</div>
                </div>
                <!-- <div class="div10" @click="stretch1('10')" @mouseenter="showback('ten')" @mouseleave="leave()">
                  <span>分类号（大组）</span>
                  <img :src="require('/static/images/patentIcon.png')" alt="">
                </div>
                <div class="applant" v-show="isTags('10')">
                    <div class="con2" :class="{ active10: style10 }">
                        <div v-for="(v,k) in applant" :key="k">
                          <span>{{v.name}}</span><span>({{v.count}})</span>
                        </div>
                    </div>
                    <div class="morebut" @click="more10" v-show="!style10">+ 更多</div>
                    <div class="morebut" @click="les10" v-show="style10">- 收起</div>
                </div>
                <div class="div11" @click="stretch1('11')" @mouseenter="showback('ele')" @mouseleave="leave()">
                  <span>分类号（小组）</span>
                  <img :src="require('/static/images/patentIcon.png')" alt="">
                </div>
                 <div class="applant" v-show="isTags('11')">
                    <div class="con2" :class="{ active11: style11 }">
                        <div v-for="(v,k) in applant" :key="k">
                          <span>{{v.name}}</span><span>({{v.count}})</span>
                        </div>
                    </div>
                    <div class="morebut" @click="more11" v-show="!style11">+ 更多</div>
                    <div class="morebut" @click="les11" v-show="style11">- 收起</div>
                </div> -->
                <!-- <div class="div12" @click="stretch1('12')" @mouseenter="showback('twe')" @mouseleave="leave()">
                  <span>外观分类</span>
                  <img :src="require('/static/images/patentIcon.png')" alt="">
                </div>
                 <div class="applant" v-show="isTags('12')">
                    <div class="con2" :class="{ active12: style12 }">
                        <div v-for="(v,k) in applant" :key="k">
                          <span>{{v.name}}</span><span>({{v.count}})</span>
                        </div>
                    </div>
                    <div class="morebut" @click="more12" v-show="!style12">+ 更多</div>
                    <div class="morebut" @click="les12" v-show="style12">- 收起</div>
                </div> -->
                <div class="div13" @click="stretch1('13')" @mouseenter="showback('thr')" @mouseleave="leave()">
                  <span>发明人</span>
                  <img :src="require('/static/images/patentIcon.png')" alt="">
                </div>
                 <div class="applant" v-show="isTags('13')">
                    <div class="con2" :class="{ active13: style13 }">
                        <div v-for="(v,k) in arr13" :key="k" @click="pushF(v.key)">
                          <span >{{v.key}}</span>
                          <span>({{v.count}})</span>
                        </div>
                    </div>
                    <div class="morebut" @click="more13" v-show="!style13">+ 更多</div>
                    <div class="morebut" @click="les13" v-show="style13">- 收起</div>
                </div>
                 <div class="div14" @click="stretch1('14')" @mouseenter="showback('fort')" @mouseleave="leave()">
                  <span>代理机构</span>
                  <img :src="require('/static/images/patentIcon.png')" alt="">
                </div>
                <div class="applant" v-show="isTags('14')">
                    <div class="con2" :class="{ active14: style14 }">
                        <div v-for="(v,k) in arr14" :key="k">
                          <span >{{v.key}}</span>
                          <span>({{v.count}})</span>
                        </div>
                    </div>
                    <div class="morebut" @click="more14" v-show="!style14">+ 更多</div>
                    <div class="morebut" @click="les14" v-show="style14">- 收起</div>
                </div>
                <!-- <div class="div15" @click="stretch1('15')" @mouseenter="showback('fir')" @mouseleave="leave()">
                  <span>代理人</span>
                  <img :src="require('/static/images/patentIcon.png')" alt="">
                </div>
                <div class="applant" v-show="isTags('15')">
                    <div class="con2" :class="{ active15: style15 }">
                        <div v-for="(v,k) in applant" :key="k">
                          <span>{{v.name}}</span><span>({{v.count}})</span>
                        </div>
                    </div>
                    <div class="morebut" @click="more15" v-show="!style15">+ 更多</div>
                    <div class="morebut" @click="les15" v-show="style15">- 收起</div>
                </div> -->
            </div>
          </div>
        </div>
        <div class="box-right">
            <div class="right-search">
              <input type="text" v-model.trim="word" @change="ch" @keyup.enter="search">
              <div class="right-search-div">
                 <img :src="require('/static/images/search-err.png')" @click="clear" alt="">
                <span @click="search">搜索</span>
                <img @click="search" :src="require('/static/images/patentfind.png')" alt="">
              </div>
            </div>
            <div class="right-detail">
                <div v-show="sqrmc.length!=0">
                  <span>申请人：（  </span>
                   <span class="sqrmc-span" v-for="(v, k) in sqrmc" :key="k">
                  {{ v }}
                  <i class="el-icon-close" @click="removeS(v)"></i>
                </span>
                  <span>  ）</span>
                </div>
                <div v-show="fmr.length!=0">
                  <span>发明人：（  </span>
                   <span class="sqrmc-span" v-for="(v, k) in fmr" :key="k">
                  {{ v }}
                  <i class="el-icon-close" @click="removeF(v)"></i>
                </span>
                  <span>  ）</span>
                </div>
            </div>
            <div class="right-list">
                <div v-for="(v,k) in tableData" :key="k" style="margin-bottom:24px;">
                    <div class="list-title">
                      <!-- <el-checkbox style="margin-right:8px;vertical-align: middle;"></el-checkbox> -->
                      <span>{{k+1}}.</span><span>【中国{{v.zllx}}】</span>
                      <span class="title-span1" style="cursor: pointer;" @click="tomore(v.shenqinghao)">{{v.shenqinghao}}</span>{{v.zymc}}
                      <span class="title-span2" v-if="v.zlstatus== '02'">{{status[v.zlstatus]}}</span>
                      <span class="title-span2 ac1" v-if="v.zlstatus== '01'">{{status[v.zlstatus]}}</span>
                      <span class="title-span2 ac2" v-if="v.zlstatus== '03'">{{status[v.zlstatus]}}</span>
                      <!-- <span class="title-span2">47.0分</span> -->
                    </div>
                    <div class="list-detail">
                      <!-- <div><img src="" alt=""></div> -->
                      <div class="list-right">
                          <div class="right1">
                              <div>
                                  <div>
                                    <span>公开（公告）号：</span>
                                    <span class="colorcha">{{v.sqgkh}}</span>
                                  </div>
                                  <div>
                                    <span>申请日：</span>
                                    <span class="colorcha">{{v.sqr}}</span>
                                  </div>
                                  <div>
                                    <span>公开（公告）日：</span>
                                    <span class="colorcha">{{v.sqgkr}}</span>
                                  </div>
                              </div>
                              <div>
                                  <div>
                                     <span>申请（专利权）人：</span>
                                    <span>{{v.sqrmc}}</span>
                                  </div>
                                  <div>
                                     <span>主分类号：</span>
                                    <span class="colorcha">{{v.ipcflh}}</span>
                                  </div>
                                  <div>
                                     <span>代理机构：</span>
                                    <span>{{v.dljg}}</span>
                                  </div>
                              </div>
                          </div>
                          <div style="clear:both;"></div>
                          <div style="width:1200px;font-size:18px;overflow:hidden;text-overflow: ellipsis;-webkit-line-clamp: 5;display: -webkit-box;-webkit-box-orient: vertical;">摘要：<span>{{v.zlzy}}</span></div>
                      </div>
                    </div>
                </div>
            </div>
            <div class="search-page">
                <div class="flex align-center" style="background-color:transparent;">
                  <el-pagination :current-page="from + 1" style="margin:0 auto;width: 1000px;text-align:center;padding-top:25px;font-size: 18px!important;"
                    :page-sizes="[20, 50, 100, 200]"
                    :page-size="size"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total"
                    @size-change="pageSizeChanged"
                    @current-change="currentChanged"
                  />
                </div>
            </div>
        </div>
   </div>
   </scale-box>
</template>

<script>
import scaleBox from "../../components/data/sclae-box";

export default {
  name: '',
  data(){
    return {
      change:'sel',
      change2:'',
      tableData:[],
      style1:false,
      style2:false,
      style3:false,
      style4:false,
      style5:false,
      style6:false,
      style7:false,
      style8:false,
      style9:false,
      style10:false,
      style11:false,
      style12:false,
      style13:false,
      style14:false,
      style15:false,
      arr2:[],
      arr3:[],
      arr4:[],
      arr5:[],
      arr8:[],
      arr9:[],
      arr13:[],
      arr14:[],
      firstid:[],
      value1:'',
      from:0,
      total:0,
      size:20,
      province:"",
      city:"",
		county:"",
		level:"",
    word:'',
    status:{
        '01': '申请中',
        '02': '有效',
        '03': '无效',
      },
      sqrmc:[],
      fmr:[]
    }
  },
  components: {
    scaleBox,
  
    
  },
  watch:{
    
    
  },
  methods: {
   changetype(val){
    this.change = val
   },
   showback(val){
    this.change2 = val
   },
   leave(){
    this.change2 = ""
   },
   ch(){
    this.firstid = [];
    this.sqrmc = []
   },
   stretch1(val) {
      if (this.firstid.includes(val)) {
        for (var i = 0; i < this.firstid.length; i++) {
          if (val == this.firstid[i]) {
            this.firstid.splice(i, 1)
          }
        }
      } else {
        this.firstid.push(val);
        let agg = "";
        if(val == "2"){
          agg = "sqrmc";
        }else if(val == "3"){
          agg = "sqr"
        }else if(val == "4"){
          agg = "gkr"
        }else if(val == "5"){
          agg = "srr"
        }else if(val == "8"){
          agg = "fenlei1"
        }else if(val == "9"){
          agg = "fenlei2"
        }else if(val == "13"){
          agg = "fmr"
        }else if(val == "14"){
          agg = "dljg"
        }
        this.$http.post(this.GLOBAL.new_url + '/patent/service/data/patentQueryAgg',
					{
					"level": this.level,
          "province": this.province,
          "city": this.city,
          "county":this.county,
          // "level":"",
          // "province": "",
          // "city": "",
          // "county":"",
           "from": this.from,
					"size":1000,
            "zymc":this.word,
          "zlzy":"",
          "fmr":[],
          "sqrmc":[],
          "lx":[],
          "fenlei":[],
          "agg":agg
					}
            ).then((res) => {
              // console.log(res)
              if(val == "2"){
              this.arr2 = res.data.data;
            }else if(val == "3"){
             this.arr3 = res.data.data.reverse();
            }else if(val == "4"){
              this.arr4 = res.data.data.reverse();
            }else if(val == "5"){
              this.arr5 = res.data.data.reverse();
            }else if(val == "8"){
              this.arr8 = res.data.data;
            }else if(val == "9"){
              this.arr9 = res.data.data;
            }else if(val == "13"){
              this.arr13 = res.data.data;
            }else if(val == "14"){
              this.arr14 = res.data.data;
            }
                // console.log(res)
			
            } 
			)		
      }
    },
    isTags(val) {
      if (this.firstid.includes(val)) {
        return true;
      } else {
        return false;
      }
    },
    pageSizeChanged(val){
		this.size = val
		this.from =0;
	    this.getData()
	},
     currentChanged(val){
		this.from = val - 1
		this.getData()
	 },
   more1(){
    this.style1 = true
   },
  les1(){
    this.style1 = false
  },
  more2(){
    this.style2 = true
   },
  les2(){
    this.style2 = false
  },
  more3(){
    this.style3 = true
   },
  les3(){
    this.style3 = false
  },
  more4(){
    this.style4 = true
   },
  les4(){
    this.style4 = false
  },
  more5(){
    this.style5 = true
   },
  les5(){
    this.style5 = false
  },
  more6(){
    this.style6 = true
   },
  les6(){
    this.style6 = false
  },
  more7(){
    this.style7 = true
   },
  les7(){
    this.style7 = false
  },
  more8(){
    this.style8 = true
   },
  les8(){
    this.style8 = false
  },
  more9(){
    this.style9 = true
   },
  les9(){
    this.style9 = false
  },
  more10(){
    this.style10 = true
   },
  les10(){
    this.style10 = false
  },
  more11(){
    this.style11 = true
   },
  les11(){
    this.style11 = false
  },
  more12(){
    this.style12 = true
   },
  les12(){
    this.style12 = false
  },
  more13(){
    this.style13 = true
   },
  les13(){
    this.style13 = false
  },
  more14(){
    this.style14 = true
   },
  les14(){
    this.style14 = false
  },
  more15(){
    this.style15 = true
   },
  les15(){
    this.style15 = false
  },
  clear(){
    this.word =""
  },
  search(){
    if(this.word !=""){
      this.from = 0;
      this.getData()
    }
  },
  pushS(val){
    if (!this.sqrmc.includes(val)) {
        this.sqrmc.push(val);
      }
      this.from = 0;
      this.getData()
  },
  removeS(val){
    for (var i = 0; i < this.sqrmc.length; i++) {
        if (val == this.sqrmc[i]) {
          this.sqrmc.splice(i, 1);
        }
      }
      this.from = 0;
      this.getData()
  },
  pushF(val){
    // if (!this.fmr.includes(val)) {
    //     this.fmr.push(val);
    //   }
    //   this.from = 0;
    //   this.getData()
  },
  removeF(val){
    for (var i = 0; i < this.fmr.length; i++) {
        if (val == this.fmr[i]) {
          this.fmr.splice(i, 1);
        }
      }
      this.from = 0;
      this.getData()
  },
  getData(){
		
				this.$http.post(this.GLOBAL.new_url + '/patent/service/data/patentQueryAll',
					{
					// "level": this.level,
          // "province": this.province,
          // "city": this.city,
          // "county":this.county,
          "level":"",
          "province": "",
          "city": "",
          "county":"",
           "from": this.from,
					"size":this.size,
			// 		"total": this.total,
            "zymc":this.word,
          "zlzy":"",
          "fmr":this.fmr,
          "sqrmc":this.sqrmc,
          "lx":[],
          "fenlei":[]
					}
            ).then((res) => {
                console.log(res)
				this.tableData.length = 0
				this.tableData.push(...res.data.data)
         this.tableData.map((x,y)=>{
          if(x.sqr){
            x.sqr = x.sqr.split("T")[0]
          }
            if(x.sqgkr){
            x.sqgkr = x.sqgkr.split("T")[0]
            }  
                   
           })
				if (res.data.data.length != 0) this.total = res.data.data[0].total	
              } 
			)		
	 },
    tomore(val){
         this.$router.push({
         path:'/moreDetail',
         query:{
            hao:val
         }
      })
     },
      
  },
   mounted() {
    this.province=localStorage.getItem('province');
      this.city=localStorage.getItem('city');
      this.county=localStorage.getItem('county');
      this.level=localStorage.getItem('level');
      if(this.county =="null"){
         this.county="";
      }
		if(this.level == "null"){
         this.level="";
      }
      this.word = this.$route.query.word;
       this.getData()
    },
    beforeCreate() {
            document.querySelector('body').setAttribute('style', 'background-color:#1C3E77;')
        },
}
</script>

<style scoped>
  .search-box{
    width: 1920px;
    height: 1028px;
    /* border:1px solid red; */
    /* padding: 16px 0 28px; */
     margin: 16px auto  0;
  }
  .search-box>div{
    float: left;
  }
  .box-left{
    width: 308px;
     /* border:1px solid red; */
    height: 1028px;
    background: #ffffff;
  }
  .left-top{
     width: 308px;
     height: 800px;
    overflow-y: scroll;
     background: #D9D9D9;
  }
  .top-title{
    height: 40px;
    background: #999999;
    margin-bottom: 5px;
  }
  .top-title>span{
    width: 154px;
    text-align: center;
    line-height: 40px;
    /* cursor: pointer; */
    display: inline-block;
    font-size: 16px;
    color: #FFFFFF;
  }
  .sel .span1,.mod .span2{
   background: #4096FF;
  }
  .left-chose>div{
    height: 40px;
    line-height: 40px;
    padding-left:16px;
    cursor: pointer;
    font-size: 16px;
  }
  .left-chose>div>span{
    font-size: 16px;
    color: #333333;
  }
  .left-chose>div>img{
    line-height: normal;
    float: right;
    margin:8px 20px 0 0;
    vertical-align: middle;
  }
  .one .div1,.two .div2,.three .div3,.four .div4,.five .div5,.six .div6,.seven .div7,.eight .div8,.nine .div9,.ten .div10,.ele .div11,.twe .div12,.thr .div13,.fort .div14,.fir .div15{
    background: #4096FF;
  }
  .country,.applant,.applant-date{
    height: auto!important;
    background: #ffffff;
    padding-left: 0!important;
  }
  .con{
    height: 40px!important;
    overflow: hidden;
  }
  .con>div>span:nth-of-type(1),.country-type>div>span:nth-of-type(1){
    margin-left: 16px;
  }
  .con>div>span:nth-of-type(2),.country-type>div>span:nth-of-type(2),.con2>div>span:nth-of-type(2){
    float: right;
    margin-right: 16px;
  }
  .active1{
    height: auto!important;
  }
  
  .country-type>div:hover,.con2>div:hover{
     background: rgb(235,235,235);
  }
  .morebut{
    margin-left:16px;
    color:#0958D9;
  }
  .con2,.con3{
    height: 245px!important;
    overflow: hidden;
  }
 .con2>div{
  height: 40px;
 }
  .con2>div>span:nth-of-type(1){
    display: inline-block;
    width: 200px;
    margin-left:16px;
    overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .active2{
    height: auto!important;
  }
  .applant-date>div>div:nth-of-type(1):hover{
    background: #ffffff;
  }
  .chosedate{
    margin: 10px 0 0 14px;
    height: 40px;
  }
  .chosedate>div{
    float: left;
  }
 .chosedate :deep(.el-input__inner),:deep(.el-date-editor){
	width:100px;
  height: 35px;
  font-size: 16px;
  padding-left: 5px;
  padding-right: 0;
  border-color: #707070;
}
.date-confir{
  display: inline-block;
  width: 48px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  margin: 5px 0 0 10px;
   color: #FFFFFF;
  background: #0958D9;
}
:deep(.el-input__prefix){
  display: none;
}
.active3,.active4,.active5,.active6,.active7,.active8,.active9,.active10,.active11,.active12,.active13,.active14,.active15{
    height: auto!important;
  }
  .box-right{
    width: 1580px;
    margin-left: 12px;
  }
  .right-search{
    position: relative;
  }
  .right-search>input{
    width: 1270px;
    padding:0 275px 0 35px ;
    height: 64px;
    outline: none;
    font-size: 24px;
    border: none;
    background: #FFFFFF;
  }
  .right-search-div{
    position: absolute;
    right: 32px;
    top:10px;
  }
  .right-search-div>span{
    margin: 0 32px;
    font-size: 20px;
    cursor: pointer;
    color: #4096FF;
    vertical-align: middle;
  }
  .right-search-div>img{
    cursor: pointer;
    vertical-align: middle;
  }
  .right-detail{
    min-height: 64px;
    padding: 12px 0 0 32px;
    background: rgba(217,217,217,0.87);
    margin-bottom: 8px;
    overflow-y: auto;
  }
  .right-list{
    padding: 32px 0 0 24px;
    height: 768px;
    overflow-y: auto;
    background: #FFFFFF;
    /* border:1px solid red; */
  }
  .list-title{
    font-size: 16px;
    margin-bottom: 25px;
  }
  .list-title :deep(.el-checkbox__inner){
    width: 24px;
    height: 24px;
  }
  :deep(.el-checkbox__inner::after){
    width: 8px!important;
    height: 18px!important;
    left: 6px!important;
}
.title-span1{
  color: #0958D9;
  margin: 0 32px;
}
.title-span2{
  width: 160px;
  height: 40px;
  line-height: 40px;
  margin-left: 32px;
  color: #FFFFFF;;
  text-align: center;
  vertical-align: middle;
  background: #2C7AF9;
  display: inline-block;
}
.ac1{
  background: #0FC109;
}
.ac2{
  background: #E51E1E;
}
.list-detail{
  height: 250px;
}
.list-detail>div{
  float: left;
}
.list-detail>div>img{
  width: 160px;
  height: 248px;
  border: 1px solid #707070;
}
.list-right{
  margin-left: 24px;
}
.right1{
  font-size: 16px;
  height: 110px;
}
.right1>div{
  float: left;
  /* width: 450px; */
}
.right1>div>div{
  margin-bottom: 15px;
}
.right1>div>div>span{
  vertical-align: middle;
}
.right1>div>div>span:nth-child(2n){
  width: 380px;
  display: inline-block;
  /* border:1px solid red; */
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
}
.colorcha{
  color:#0958D9;
}
.search-page{
  margin-top: 5px;
  height: 70px;
  background: #FFFFFF;
}

:deep(.el-pagination) span{
  
	font-size: 18px!important;
 }
:deep(.el-pager li){
	font-size: 18px;
 }
 .flex :deep(.el-input__inner){
    font-size: 18px;
 }
 .con3>div>div{
  height: 40px;
   
 }
.con3>div>div:hover{
  background: rgb(235,235,235);
}
.con3>div>div>span:nth-of-type(1) {
    display: inline-block;
    width: 290px;
    margin-left: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.right-detail>div{
  font-size: 16px;
  float: left;
  margin: 0 20px 15px 0;
}
.el-icon-close{
  cursor: pointer;
  font-size: 18px;
  color: #0958D9;
}
.sqrmc-span{
  display: inline-block;
  padding: 0 10px;
  border: 1px solid #2C7AF9;
}
</style>



